<template>
  <the-base-form
    :url="url"
    :object="bonus"
    :is-editing="isEditing"
    singularName="Solicitud de Bonificación"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md12>
      <ValidationProvider
        name="Categoria servicio de salud"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          name="msCategory"
          :items="ms_categories"
          v-model="msCategory"
          :loading="loadingMSCategory"
          label="Categoria servicio de salud"
          :error-messages="errors"
        ></v-autocomplete>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Servicio de salud"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          name="btype"
          :items="medical_services"
          :loading="loadingMedicalService"
          v-model="bonus.service"
          :value="bonus.service"
          label="Servicio de salud"
          :error-messages="errors"
        ></v-autocomplete>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Previsión de salud"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          name="healthCoverage"
          :items="health_coverages"
          :loading="loadingHealthCoverage"
          v-model="bonus.coverage"
          :value="bonus.coverage"
          label="Previsión de Salud"
          :error-messages="errors"
        ></v-autocomplete>
      </ValidationProvider>
    </v-flex>

    <v-flex xs12 sm12 md6>
      <v-menu
        v-model="calendar"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <ValidationProvider
            name="Fecha de orden (liquidación seguro/boleta/bono)"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              :value="computedDateFormatted"
              label="Fecha de orden (liquidación seguro/boleta/bono)"
              hint="dd-mm-aaaa"
              persistent-hint
              prepend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </template>
        <v-date-picker
          locale="es-CL"
          v-model="bonus.order_date"
          no-title
          @input="calendar = false"
        ></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Número Siniestro/bono/boleta"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :value="bonus.order_number"
          v-model="bonus.order_number"
          hint="número siniestro/bono/boleta"
          label="Número Siniestro/bono/boleta"
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Monto copago/Monto cobrado (de liquidación) bono/boleta"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          :value="bonus.requested_amount"
          v-model="bonus.requested_amount"
          hint="($)"
          label="Monto copago/Monto cobrado (de liquidación) bono/boleta"
          :outlined="false"
          v-bind:options="options"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Monto reembolsado por seguro de salud (Afiliado/a)"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          :value="bonus.coverage_amount"
          v-model="bonus.coverage_amount"
          hint="($)"
          label="Monto reembolsado por seguro de salud (Afiliado/a)"
          :outlined="false"
          v-bind:options="options"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-autocomplete
        name="affiliate_dependents"
        :items="affiliate_dependents"
        v-model="bonus.dependents"
        label="Cargas familiares"
        hint="Cargas familiares"
        multiple
        chips
      ></v-autocomplete>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'
import VuetifyMoney from '@/components/utils/VuetifyMoney'
import Utils from '@/utils'

export default {
  data: () => ({
    msCategory: null,
    affiliate_dependents: [],
    ms_categories: [],
    medical_services: [],
    health_coverages: [],
    loadingMSCategory: true,
    loadingMedicalService: true,
    loadingHealthCoverage: true,
    calendar: false,
    options: {
      locale: 'es-ES',
      prefix: 'CLP$',
      suffix: '',
      precision: 0,
    },
    options_return: {
      locale: 'es-ES',
      prefix: 'CLP$',
      suffix: 'Aproximado',
      precision: 0,
    },
    loading: false,
  }),
  components: {
    TheBaseForm,
    VuetifyMoney,
  },
  props: {
    bonus: Object,
    isEditing: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    url() {
      return 'affiliate_bonus_list/'
    },
    computedDateFormatted() {
      return Utils.formatDate(this.bonus.order_date)
    },
  },
  watch: {
    msCategory(val) {
      this.medical_services = []
      this.loadingMedicalService = true
      this.axios
        .get(this.$api('medical_services/', { category: val }))
        .then((data) => {
          data.data.forEach((MedicalService) => {
            this.medical_services.push({
              value: MedicalService.id,
              text: MedicalService.service,
            })
          })
          this.loadingMedicalService = false
        })
        .catch((error) => console.log(error))
    },
  },
  created() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('affiliate_bonus_list/affiliate_dependents/'))
        .then((data) => {
          data.data.forEach((AffiliateDependent) => {
            this.affiliate_dependents.push({
              value: AffiliateDependent.id,
              text:
                AffiliateDependent.first_name +
                ' ' +
                AffiliateDependent.last_name,
              // text: AffiliateDependent.run,
            })
          })
        })
        .catch((error) => console.log(error))
      this.axios
        .get(this.$api('ms_categories/'))
        .then((data) => {
          data.data.forEach((MSCategory) => {
            this.ms_categories.push({
              value: MSCategory.id,
              text: MSCategory.category,
            })
          })
          this.msCategory = this.bonus.get_service_category_id
          this.loadingMSCategory = false
        })
        .catch((error) => console.log(error))
      this.axios
        .get(
          this.$api('medical_services/', {
            category: this.bonus.get_service_category_id,
          })
        )
        .then((data) => {
          data.data.forEach((MedicalService) => {
            this.medical_services.push({
              value: MedicalService.id,
              text: MedicalService.service,
            })
          })
          this.loadingMedicalService = false
        })
        .catch((error) => console.log(error))
      this.axios
        .get(this.$api('affiliate_bonus_list/health_coverage'))
        .then((data) => {
          data.data.forEach((Coverage) => {
            this.health_coverages.push({
              value: Coverage.id,
              text: Coverage.coverage,
            })
          })
          this.loadingHealthCoverage = false
        })
        .catch((error) => console.log(error))
    }
  },
  methods: {},
}
</script>
