<template>
  <the-base-form
    :url="url"
    :object="bonus"
    singularName="Solicitud de Bonificación"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md4>
      <ValidationProvider
        name="Servicio médico"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Servicio médico"
          disabled
          :value="bonus.get_service_display"
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md4>
      <ValidationProvider
        name="Categoria servicio médico"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Categoria servicio médico"
          disabled
          :error-messages="errors"
          :value="bonus.get_service_category_display"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md4>
      <ValidationProvider
        name="Previsión de salud"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Previsión de salud"
          disabled
          :error-messages="errors"
          :value="bonus.get_coverage_display"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>

    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Monto total bono/boleta (Afiliado)"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          :value="bonus.requested_amount"
          v-model="bonus.requested_amount"
          hint="($)"
          label="Monto total bono/boleta (Afiliado)"
          :readonly="true"
          :disabled="true"
          :outlined="false"
          v-bind:options="options"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Monto cobertura/seguro de salud (Afiliado)"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          :value="bonus.coverage_amount"
          v-model="bonus.coverage_amount"
          hint="($)"
          label="Monto cobertura/seguro de salud (Afiliado)"
          :readonly="true"
          :disabled="true"
          :outlined="false"
          v-bind:options="options"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs10 sm10 md5>
      <ValidationProvider
        name="Valor máximo bonificación de salud"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          v-model="bonus.bonus_amount"
          label="Valor servicio médico (ARANCEL FONASA)"
          :readonly="false"
          :outlined="false"
          :error-messages="errors"
          v-bind:options="options"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs2 sm2 md1>
      <div class="text-center align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success darken-2"
              fab
              small
              dark
              :loading="loading"
              v-bind="attrs"
              v-on="on"
              @click="calculate()"
            >
              <v-icon>mdi-calculator</v-icon>
            </v-btn>
          </template>
          <span>Calcular devolución</span>
        </v-tooltip>
      </div>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Valor a devolver a afiliado"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          v-model="bonus.return_amount"
          label="Valor devolver a afiliado (Calculado)"
          :readonly="true"
          :outlined="false"
          :disabled="true"
          v-bind:options="options_return"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-select
        name="payment_method"
        :items="payment_methods"
        v-model="bonus.payment"
        label="Método de pago"
        hint="Método de pago"
      ></v-select>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'
import VuetifyMoney from '@/components/utils/VuetifyMoney'
export default {
  data: () => ({
    payment_methods: [],
    options: {
      locale: 'es-ES',
      prefix: 'CLP$',
      suffix: '',
      precision: 0,
    },
    options_return: {
      locale: 'es-ES',
      prefix: 'CLP$',
      suffix: 'Aproximado',
      precision: 0,
    },
    loading: false,
  }),
  components: {
    TheBaseForm,
    VuetifyMoney,
  },
  props: {
    bonus: Object,
  },
  computed: {
    url() {
      return 'bonus_list/'
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },
  watch: {},
  created() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('bonus_list/get_payment_methods/'))
        .then(data => {
          data.data.forEach(PaymentMethod => {
            this.payment_methods.push({
              value: PaymentMethod.id,
              text: PaymentMethod.payment_method,
            })
          })
        })
        .catch(error => console.log(error))
    }
  },
  methods: {
    calculate() {
      this.loading = true
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(
            this.$api(
              'bonus_list/' + this.$route.params.id + '/get_return_amount/',
              { bonus_amount: this.bonus.bonus_amount }
            )
          )
          .then(data => {
            data.data.forEach(ReturnAmount => {
              this.bonus.return_amount = ReturnAmount.return_amount
              this.loading = false
            })
          })
          .catch(error => {
            console.log(error)
            this.loading = false
          })
      }
    },
  },
}
</script>
